import React, { Component } from "react";
import styled, { css } from "styled-components";

class VideoEditResetIcon extends Component {
  constructor() {
    super();
  }
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <path
          fill="#333"
          d="M13.65 2.35A7.95 7.95 0 0 0 7.17.04C3.5.41.48 3.39.07 7.06-.48 11.91 3.27 16 8 16a7.98 7.98 0 0 0 7.21-4.56c.32-.67-.16-1.44-.9-1.44-.37 0-.72.2-.88.53a5.994 5.994 0 0 1-6.8 3.31c-2.22-.49-4.01-2.3-4.48-4.52A6.002 6.002 0 0 1 8 2c1.66 0 3.14.69 4.22 1.78l-1.51 1.51c-.63.63-.19 1.71.7 1.71H15c.55 0 1-.45 1-1V2.41c0-.89-1.08-1.34-1.71-.71z"
        />
      </svg>
    );
  }
}

export default VideoEditResetIcon;
