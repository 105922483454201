import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'antd';
import { observer, inject } from 'mobx-react';
import { Wrap, IndexWrap } from 'components/style/custom-styled-components';

import EventDetailPostList from 'containers/eventDetailPostList';
import LikeBtn from 'components/likeBtn';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';
import ItemBuyRequired from 'components/popModal/itemBuyRequired';

import * as eventAPI from 'axios/eventAPI';
import * as postAPI from 'axios/postAPI';

import history, { openNewWindow } from 'utils/history';
import getRemainTime from 'utils/myCountDown';
import * as myVideo from 'utils/myVideo';
import overThousand from 'utils/overThousand';

import back_ico from 'images/back_ico_w.svg';

const MainWrap = styled.div`
	position: relative;
	padding-top: 68px;
	margin-bottom: 40px;
	width: 100%;
	background-image: linear-gradient(to right, #faaca8 0%, #d7cef1 100%);
`;

const MainWrapBottom = styled.div`
	position: absolute;
	bottom: -2px;
	width: 100%;
	height: 140px;
	background-image: linear-gradient(
		to top,
		#f8f8f8 0%,
		rgba(255, 255, 255, 0) 100%
	);
`;

const NavBar = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	position: fixed;
	max-width: 1024px;
	top: 0;
	z-index: 1001;
	background-image: linear-gradient(to right, #faaca8 0%, #d7cef1 100%);
	height: 44px;
	line-height: 44px;
`;
const MainTitle = styled.div`
	width: 100%;
	text-align: center;
	text-shadow: 2px 5px 30px #e2aab1;
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 20px;
	font-weight: 700;
	padding: 0px 16px;
	word-break: keep-all;
`;
const MainContent = styled.div`
	margin-top: 23px;
	margin-bottom: 10px;
	width: 100%;
	text-align: center;
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 400;
	word-break: keep-all;
	padding: 0px 16px;
`;
const MainStatusWrap = styled.div`
	display: flex;
	width: 100%;
	padding: 13px 33px;
`;
const StatusItem = styled.div`
	font-size: 14px;
	font-weight: 500;
`;
const StatusBig = styled.div`
	text-shadow: 0 2px 2px rgba(255, 255, 255, 0.3);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 23px;
	font-weight: 700;
	text-align: center;
	width: 100%;
`;
const MainStoreProfileWrap = styled.div`
	display: flex;
	background: white;
	width: 100%;
	height: 80px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #ffffff;
	padding: 18px 16px;
`;
const Title = styled.div`
	width: 100%;
	height: 28px;
	color: #333333;
	font-family: Lato;
	font-size: 23px;
	font-weight: 900;
	line-height: 26px;
	margin-bottom: 4px;
`;
const FilterBtn = styled.div`
	position: absolute;
	right: 16px;
	vertical-align: text-bottom;
	display: inline-block;
	width: 72px;
	height: 25px;
	border-radius: 50px;
	text-align: center;
	line-height: 21px;
	cursor: pointer;
	border: 2px solid rgba(130, 143, 247, 0.2);
	:hover,
	focus {
		opacity: 0.8;
	}
`;

const FilterText = styled.span`
	color: #828ff7;
	font-family: 'Noto Sans CJK KR';
	font-size: 13px;
	font-weight: 700;
	vertical-align: text-top;
`;
const FilterDown = styled.div`
	position: absolute;
	text-align: center;
	display: none;
	right: 16px;
	top: 26px;
	width: 72px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background-color: #ffffff;
	${props =>
		props.toggle &&
		css`
			display: block;
			z-index: 10;
		`}
`;
const FilterDownText = styled.div`
	color: #828ff7;
	font-family: 'Noto Sans CJK KR';
	font-size: 13px;
	font-weight: 700;
	padding: 7px 0px;
	cursor: pointer;
	:hover,
	focus {
		opacity: 0.8;
	}
`;
const StoreImg = styled.div`
	flex: 0 0 auto;
	width: 44px;
	height: 44px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
	border-radius: 50%;
	background-size: cover;
	background-position: center;
`;
const StoreInfo = styled.div`
	margin-left: 12px;
	width: 100%;
	flex: 0 1 auto;
	overflow: hidden;
	white-space: nowrap;

	p {
		margin: 0px;
	}
	p.store-prefix {
		color: #bebebe;
		font-family: Lato;
		font-size: 12px;
		font-weight: 700;
		line-height: 23px;
		text-transform: uppercase;
	}
	p.store-name {
		color: #828ff7;
		font-family: 'Noto Sans CJK KR';
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
	}
`;
const StoreBtn = styled.div`
	width: 76px;
	height: 28px;
	border-radius: 14px;
	background-color: rgba(130, 143, 247, 0.2);
	text-align: center;
	cursor: pointer;
	color: #828ff7;
	font-family: 'Noto Sans CJK KR';
	font-size: 13px;
	font-weight: 700;
	line-height: 28px;
`;
const ApplyWrap = styled.div`
	width: 100%;
	max-width: 1024px;
	margin: auto;
	padding: 10px 16px;
	position: fixed !important;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-image: linear-gradient(
		to top,
		rgba(248, 248, 248, 1) 0%,
		rgba(255, 255, 255, 0) 100%
	);
`;
const ApplyBtn = styled.button`
	border: none;
	width: 100%;
	height: 44px;
	box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
	border-radius: 68px;
	background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
	${props =>
		props.disabled &&
		css`
			background-image: none;
			background-color: rgb(150, 150, 150);
		`}
`;
@inject('cacheStore')
@inject('userStore')
@observer
class EventDetailRefactor extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = true;
		this.state = this.props.cacheStore.eventDetailData
			? this.props.cacheStore.eventDetailData
			: {
					initData: false,
					eventData: [],
					filter: 'popular',
					filterText: '인기순',
					filterDropDown: false,
					remainedTime: '',
					is_liked: false,
			  };
	}
	componentWillMount() {
		if (this.props.cacheStore.eventDetailData) {
			if (
				this.props.cacheStore.eventDetailData.eventData.id !=
				this.props.match.params.id
			) {
				this.setState({
					initData: false,
					eventData: [],
					filter: 'popular',
					filterText: '인기순',
					filterDropDown: false,
					remainedTime: '',
				});
			}
		}
	}
	async componentDidMount() {
		const eventReq = {
			id: this.props.match.params.id,
			headers: this.props.userStore.auth_token
				? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
				: false,
		};
		const event = await eventAPI.getEvent(eventReq).then(res => {
			return res.data;
		});
		var duration = getRemainTime(event.ended_at);
		if (this._isMounted) {
			this.setState({
				initData: true,
				remainedTime:
					duration.days() <= 2
						? myVideo.pad(duration.days() * 24 + duration.hours(), 2) +
						  ':' +
						  myVideo.pad(duration.minutes(), 2) +
						  ':' +
						  myVideo.pad(duration.seconds(), 2)
						: `${duration.days()}일`,
				eventData: event,
				postData: event.post,
				is_liked: event.like.is_liked,

				remainedTimeInterval: setInterval(() => {
					duration = getRemainTime(event.ended_at);
					if (this._isMounted) {
						this.setState({
							remainedTime:
								duration.days() <= 2
									? myVideo.pad(duration.days() * 24 + duration.hours(), 2) +
									  ':' +
									  myVideo.pad(duration.minutes(), 2) +
									  ':' +
									  myVideo.pad(duration.seconds(), 2)
									: `${duration.days()}일`,
						});
					}
				}, 1000),
			});
		}
	}
	toggleFilterDropDown = () => {
		this.setState({
			filterDropDown: !this.state.filterDropDown,
		});
	};
	componentWillUnmount() {
		this._isMounted = false;
		this.props.cacheStore.setEventDetailData(this.state);
		clearInterval(this.remainedTimeInterval);
	}
	toggleFilter = async filter => {
		const dict = {
			popular: '인기순',
			new: '최신순',
		};
		const req = {
			ordering: filter,
			event: this.props.match.params.id,
			headers: this.props.userStore.auth_token
				? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
				: false,
		};
		const posts = await Promise.all([
			postAPI.getPostList(req).then(res => {
				return res.data.data;
			}),
		]);
		this.setState({
			filter: filter,
			filterText: dict[filter],
			filterDropDown: false,
			postData: posts[0],
		});
	};
	toApply = () => {
		this.props.userStore.toggleItemBuyRequired();
	};
	toggleIsLike = () => {
		this.setState({
			is_liked: !this.state.is_liked,
		});
	};
	render() {
		const is_liked = this.state.is_liked;
		return (
			<Wrap>
				<LoginRequiredModal bottom={'0px'} />
				<IndexWrap style={{ paddingTop: 0 }}>
					<ItemBuyRequired
						bottom="0px"
						link={this.state.eventData.store && this.state.eventData.store.link}
					/>
					<MainWrap>
						<MainWrapBottom />
						<NavBar>
							<div style={{ width: '50%', paddingLeft: 19 }}>
								<img
									src={back_ico}
									onClick={() => {
										history.goBack();
									}}
									style={{
										width: 19,
										verticalAlign: 'middle',
										cursor: 'pointer',
									}}
								/>
							</div>
							<div
								style={{
									width: '50%',
									textAlign: 'right',
									paddingRight: is_liked ? 14 : 18,
									paddingTop: is_liked ? 12 : 5,
								}}
							>
								<LikeBtn
									is_liked={is_liked}
									mode="comment"
									id={this.state.eventData.id}
									option="event"
									noClickedColor="rgb(255,255,255)"
									toggleIsLike={this.toggleIsLike}
								/>
							</div>
						</NavBar>
						{this.state.initData ? (
							<Fragment>
								<MainTitle> {this.state.eventData.title} </MainTitle>
								<MainContent> {this.state.eventData.content} </MainContent>
								<MainStatusWrap>
									<StatusBig>
										{this.state.remainedTime}
										<StatusItem> 남은 시간 </StatusItem>
									</StatusBig>
									<StatusBig>
										{this.state.eventData.participant.count}
										<StatusItem> 참여자 </StatusItem>
									</StatusBig>
									<StatusBig>
										{this.state.eventData.like.count}
										<StatusItem> 좋아요 </StatusItem>
									</StatusBig>
								</MainStatusWrap>
								<div
									style={{
										position: 'relative',
										padding: '0px 16px',
										width: '100%',
										bottom: '-7px',
									}}
								>
									<MainStoreProfileWrap>
										<StoreImg
											style={{
												backgroundImage: `url('${this.state.eventData.store.image}')`,
											}}
										/>
										<StoreInfo>
											<p className="store-prefix">WITH</p>
											<p className="store-name">
												{this.state.eventData.store.name}
											</p>
										</StoreInfo>
										<div style={{ padding: '8px 0px' }}>
											<StoreBtn
												onClick={() => {
													openNewWindow(this.state.eventData.store.link);
												}}
											>
												{' '}
												구경하기{' '}
											</StoreBtn>
										</div>
									</MainStoreProfileWrap>
								</div>
							</Fragment>
						) : (
							<SpinnerCurtain fullSize={true} />
						)}
					</MainWrap>

					{this.state.initData ? (
						<div
							style={{
								position: 'relative',
								paddingLeft: 16,
								paddingRight: 16,
							}}
						>
							<Title>
								<span>Challengers</span>
								<FilterBtn onClick={this.toggleFilterDropDown}>
									<FilterText>
										{' '}
										{this.state.filterText}{' '}
										<Icon
											type="down"
											style={{ fontSize: '11px', verticalAlign: 'middle' }}
										/>{' '}
									</FilterText>
								</FilterBtn>
								<FilterDown toggle={this.state.filterDropDown}>
									<FilterDownText onClick={() => this.toggleFilter('popular')}>
										인기순
									</FilterDownText>
									<FilterDownText onClick={() => this.toggleFilter('new')}>
										최신순
									</FilterDownText>
								</FilterDown>
							</Title>
							<EventDetailPostList
								data={this.state.eventData.example.concat(this.state.postData)}
							/>
							<ApplyWrap>
								<ApplyBtn onClick={this.toApply}>참여하기</ApplyBtn>
							</ApplyWrap>
						</div>
					) : null}
				</IndexWrap>
			</Wrap>
		);
	}
}
export default EventDetailRefactor;
