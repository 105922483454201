import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";
import Beforeunload from "react-beforeunload";

import { Wrap, IndexWrap } from "components/style/custom-styled-components";
import SampleVideoOnlyPoster from "components/sampleVideoOnlyPoster";
import LikeBtn from "components/likeBtn";

import * as userAPI from "axios/userAPI";

import commentIcon from "images/detail_comment_ico.png";
import history from "utils/history";

const VideoWrap = styled.div`
  position: relative;
  height: 100vh;
`;

const PostTitle = styled.div`
  position: absolute;
  bottom: 36px;
  left: 16px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;
const ProgressBarWrap = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  top: 0px;
  left: 0px;
  height: 3px;
  background-color: transparent;
  width: 100%;
`;
const ProgressBar = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  position: relative;
`;
const BarSpace = styled.div`
  width: 5px;
  height: 100%;
  background-color: transparent;
`;
const ProgressFilter = styled.div`
  height: 100%;
  width: 0;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0px;
  left: 0px;
  transition: width 10s linear;
`;
const FollowBtnWrap = styled.div`
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 25px;
`;
const BackBtn = styled.i`
  position: absolute;
  z-index: 2;
  top: 32px;
  right: 21px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const WrapTitle = styled.h3`
  width: 163px;
  height: 27px;
  border: 1px solid rgba(0, 0, 0, 0);
  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 12px;
`;

const IconWrap = styled.div`
  flex: 0 0 auto;
  width: 36px;
  height: 36px;
  margin: 6px auto;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
`;

@inject("postStore")
@observer
class IosPostSamplePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: this.props.postStore.iosAddedVideoArr,
      playFault: false,
      prgBarWidth: [],
      popOver: false,
      vi: 0
    };
  }
  componentDidMount() {
    if (this.props.postStore.iosAddedVideoArr.length == 0) {
      history.push("/new-post");
    }
  }
  componentWillUnmount() {}
  setVideoIndex = id => {
    this.setState({
      vi: id
    });
  };
  setPrgBarWidth = currentDuration => {};
  render() {
    const videoLen = this.state.videos.length;
    return (
      <Wrap ref={this.myWrap} style={{ paddingBottom: 0 }}>
        <IndexWrap style={{ height: "100%" }}>
          <BackBtn
            onClick={() => {
              history.goBack();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
            >
              <path
                fill="rgb(255,255,255)"
                d="M11.24.769a.828.828 0 0 0-1.173 0L6 4.827 1.933.76A.828.828 0 1 0 .76 1.933L4.827 6 .76 10.067a.828.828 0 1 0 1.173 1.173L6 7.173l4.067 4.067a.828.828 0 1 0 1.173-1.173L7.173 6l4.067-4.067a.834.834 0 0 0 0-1.164z"
              />
            </svg>
          </BackBtn>
          <VideoWrap
            style={{
              height: "100vh",
              padding: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden"
            }}
          >
            <ProgressBarWrap>
              {this.state.videos.map((video, index) => {
                return (
                  <Fragment key={index}>
                    <ProgressBar>
                      {" "}
                      <ProgressFilter style={{ width: "0px" }} />{" "}
                    </ProgressBar>
                    {index == videoLen - 1 ? <Fragment /> : <BarSpace />}
                  </Fragment>
                );
              })}
            </ProgressBarWrap>

            {this.state.videos.map((video, index) => {
              const itsMe = index == this.state.vi ? true : false;
              return (
                <SampleVideoOnlyPoster
                  key={index}
                  videos={this.state.videos}
                  videoUrl={video.videoUrl}
                  index={index}
                  itsMe={itsMe}
                  setVideoIndex={this.setVideoIndex}
                  setPrgBarWidth={this.setPrgBarWidth}
                />
                // <div style={{width:'100%', height:'100vh', background:'blue'}}
                //     key={index}
                //     videos={this.state.videos}
                //     videoUrl={video.videoUrl}
                //     index={ index }
                //     itsMe={ index == this.state.vi ? true : false }
                //     setVideoIndex={this.setVideoIndex}
                //     setPrgBarWidth={this.setPrgBarWidth}
                // />
              );
            })}
          </VideoWrap>
        </IndexWrap>
      </Wrap>
    );
  }
}
export default IosPostSamplePage;
