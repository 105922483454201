import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import * as userAPI from 'axios/userAPI';
import history from 'utils/history';

const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	max-width: 1024px;
	height: auto;
	top: 0px;
	z-index: 1000;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
`;
const SearchInput = styled.input`
	padding-left: 43px;
	width: 100%;
	height: 44px;
	background-color: rgb(255, 255, 255);
	border: none;
`;
const IconWrapper = styled.i`
	z-index: 0;
	position: absolute;
	left: 19px;
	top: 12px;
	svg {
		margin-right: 12px;
	}
	span {
		vertical-align: text-bottom;
		font-style: normal;
		color: #333333;
		font-family: 'Noto Sans CJK KR';
		font-size: 15px;
		font-weight: 700;
	}
`;
const source = [
	{
		title: '가나다라',
		description: '샘플예제입니다',
		price: '123123',
	},
];

class SearchBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			searchVal: '',
			searching: false,
		};
	}
	componentDidMount() {
		this.searchBar.focus();
	}
	resetResults = () => {
		if (this.props.mode == 'following')
			this.props.setFollowing(this.props.data);
		else if (this.props.mode == 'follower')
			this.props.setFollower(this.props.data);
		else if (this.props.mode == 'posts')
			this.props.setPostData(this.props.data);
		else if (this.props.mode == 'items')
			this.props.setItemData(this.props.data);
		this.setState({ isLoading: false, results: this.props.data, value: '' });
		return this.props.data;
	};
	handleSearchChange = e => {
		if (this.props.mode == 'following' || this.props.mode == 'follower') {
			this.setState({
				isLoading: true,
				searchVal: e.target.value,
				searching: true,
			});

			if (e.target.value == '') return this.resetResults();

			const re = new RegExp(_.escapeRegExp(e.target.value), 'i');
			const isMatch = result => re.test(result.profile.nickname);
			this.setState({
				isLoading: false,
				results: _.filter(this.props.data, isMatch),
			});
			if (this.props.mode == 'following')
				this.props.setFollowing(_.filter(this.props.data, isMatch));
			else if (this.props.mode == 'follower')
				this.props.setFollower(_.filter(this.props.data, isMatch));
			else if (this.props.mode == 'posts')
				this.props.setPostData(_.filter(this.props.data, isMatch));
			else if (this.props.mode == 'items')
				this.props.setItemData(_.filter(this.props.data, isMatch));
		} else if (this.props.mode == 'posts') {
			this.setState({
				isLoading: true,
				searchVal: e.target.value,
				searching: true,
			});

			if (e.target.value == '') return this.resetResults();

			const re = new RegExp(_.escapeRegExp(e.target.value), 'i');
			const isMatch = result =>
				re.test(result.profile.nickname + ' ' + result.title);
			this.setState({
				isLoading: false,
				results: _.filter(this.props.data, isMatch),
			});
			this.props.setPostData(_.filter(this.props.data, isMatch));
		} else if (this.props.mode == 'items') {
			this.setState({
				isLoading: true,
				searchVal: e.target.value,
				searching: true,
			});
			if (e.target.value == '') return this.resetResults();

			const re = new RegExp(_.escapeRegExp(e.target.value), 'i');
			const isMatch = result => re.test(result.name + ' ' + result.store);
			this.setState({
				isLoading: false,
				results: _.filter(this.props.data, isMatch),
			});
			this.props.setItemData(_.filter(this.props.data, isMatch));
		}
	};
	_iconOnClick = () => {
		this.searchBar.focus();
	};
	_onFocus = () => {
		this.setState({ searching: true });
	};
	_onBlur = () => {
		if (this.state.searchVal == '') this.setState({ searching: false });
	};
	resetSearch = () => {
		this.resetResults();
		this.props.toggle();
	};
	render() {
		return (
			<Wrapper>
				<IconWrapper onClick={this._iconOnClick}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="15"
						height="15"
						viewBox="0 0 17 17"
					>
						<path
							fill="rgb(190,190,190)"
							d="M6.5 11C4.01 11 2 8.99 2 6.5S4.01 2 6.5 2 11 4.01 11 6.5 8.99 11 6.5 11zm6 0h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34A6.505 6.505 0 0 0 .05 7.32c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49z"
						/>
					</svg>
				</IconWrapper>
				<SearchInput
					ref={ref => {
						this.searchBar = ref;
					}}
					onFocus={this._onFocus}
					onBlur={this._onBlur}
					loading={this.state.isLoading}
					onChange={this.handleSearchChange}
					value={this.state.searchVal}
				/>
				<IconWrapper
					style={{
						right: '17px',
						left: 'auto',
						top: '0px',
						lineHeight: '44px',
					}}
				>
					<span onClick={this.resetSearch}>취소</span>
				</IconWrapper>
			</Wrapper>
		);
	}
}

export default SearchBar;
