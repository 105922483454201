import React, { Fragment } from 'react';
import Beforeunload from 'react-beforeunload';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import { animateScroll as scroll } from 'react-scroll';
import qs from 'querystring';
import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import VideoInput from 'components/videoInput';
import ProductAddPage from '../productAddPage';
import closeIcon from 'images/baseline_close_black_18dp.png';
import LinkSvg from 'components/myIcons/linkIcon';

import axios from 'axios';
import * as postAPI from 'axios/postAPI';
import history from 'utils/history';
import back_ico from 'images/back_ico_b.svg';
import categoryItems from 'utils/categoryItems';
import DeleteModal from 'components/popModal/deleteModal';
import { ClipLoader, PropagateLoader } from 'react-spinners';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/CheckCircle';
import QnaModal from 'components/QnaModal';
import PollModal from 'components/PollModal';
import MissionModal from 'components/MissionModal';
import TopicModal from 'components/TopicModal';
import removeLink from 'utils/removeLink';
import makeLink from 'utils/makeLink';
import getCaret from 'utils/getCaret';
import * as mediaAPI from 'axios/mediaAPI';
import photo_img from 'images/photo.png';
import imageCompression from 'browser-image-compression';
import sendTagPush from 'utils/sendTagPush';
import PostTypeSelect from 'containers/postTypeSelect';
import InfoList from 'containers/infoList';
import { ReactSortable } from 'react-sortablejs';
import UserSelectorModal from '../../components/popModal/userSelectorModal';
import WorldAndSum from 'components/worldAndSum';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = {
  buttonWrapper: {
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonNegative: {
    color: 'black',
    background: '#efefef',
    height: 48,
    width: '48%',
    borderRadius: 10,
    boxShadow: 'none',
  },
  buttonActive: {
    background: '#E2EAFA',
    color: '#1f75fe',
  },
  buttonCenter: {
    marginLeft: 8,
    marginRight: 8,
  },
};
const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  line-height: 56px;
  height: 44px;
  line-height: 44px;
  width: 100%;
  max-width: 1024px;
  z-index: 3;
  top: 0;
  background: rgb(248, 248, 248);
`;
const ContentWrap = styled.div`
  padding: 10px 16px;
  padding-bottom: 70px;
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 16px;
  font-weight: 700;
  p {
    margin-bottom: 10px;
  }
  span.sub-btn {
    float: right;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }
  div {
    position: relative;
  }
  input::placeholder {
    color: #bebebe;
    font-family: 'Noto Sans CJK KR';
    font-size: 14px;
    font-weight: 500;
  }
`;
const VideoInputWrap = styled.div`
  width: 100%;
  max-width: 1024px;
  border-radius: 6px;
  padding-bottom: 36px;
  display: flex;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none !important;
  }
  justify-content: center;
`;
const UpdateTextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  border: none;
  margin-bottom: 16px;
  padding: 14px 16px;
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  ::placeholder {
    color: #9fa4af;
    opacity: 0.5;
  }
`;
const UpdateInput = styled.input`
  width: 100%;
  height: 44px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  border: none;
  margin-bottom: 36px;
  padding: 0px 16px;
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  ::placeholder {
    color: #9fa4af;
    opacity: 0.8;
  }
`;
const IconWrapper = styled.i`
  z-index: 0;
  position: absolute;
  right: 16px;
  top: 12px;
  span {
    margin-left: 11px;
    vertical-align: text-bottom;
    font-style: normal;
    font-weight: 500;
    color: rgb(172, 172, 172);
    font-family: 'Noto Sans CJK KR';
    font-size: 14px;
  }
`;
const SubmitWrap = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 16px;
  position: fixed !important;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;
const SubmitBtn = styled.button`
  border: none;
  width: 100%;
  height: 44px;
  box-shadow: 0 10px 20px rgba(31, 117, 254, 0.3);
  background: #1f75fe;
  border-radius: 68px;
  color: #ffffff;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-image: none;
      background-color: rgb(150, 150, 150);
    `}
`;

const ToSampleBtn = styled.button`
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 15px;
  font-weight: 700;
  border: none;
  background-color: transparent;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const ProductAddPageLayout = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
`;

const AddButton = styled.button`
  background: none;
  border: none;
  font-family: 'Noto Sans CJK KR';
  font-size: 15px;
  font-weight: 700;
  margin-top: 8px;
  color: #828ff7;
  width: 100%;
  text-align: center;
  cursor: pointer;
`;

const base = 'https://star-api.lay-er.me';
@inject('cacheStore')
@inject('postStore')
@inject('userStore')
@observer
class PostEditPage extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this._doUnMountFlow = true;
    this.state = this.props.cacheStore.postEditData
      ? this.props.cacheStore.postEditData
      : {
          videoInputArr: [],
          linkArr: [],
          linkNum: 1,
          tags: undefined,
          title: '',
          profile: undefined,
          deleteMode: false,
          currentEditPostData: undefined,
          isGetLink: false,
          productAddPageOn: false,
          productAdded: [],
          selectedProduct: null,
          categorySelected: 'fashion',

          sum: null,
          loading: false,
          deleteIndex: -1,

          topicOpen: false,
          topic: null,
          changing: false,
          onlySelected: false,
        };
    this.state.mission = null;
    this.state.missionOpen = false;

    this.state.qna = null;
    this.state.qnaOpen = false;

    this.state.pollOpen = false;
    this.state.pollTitle = '';
    this.state.pollOptionA = '';
    this.state.pollOptionB = '';
  }
  handleDeleteProduct = (index) => {
    let newProductAdded = this.state.productAdded.slice();
    newProductAdded.splice(index, 1);
    this.setState({
      productAdded: newProductAdded,
      deleteIndex: -1,
    });
  };
  getImgOfProduct = (label, category) =>
    categoryItems[category].find((o) => o.label === label).src;

  getSumId = () => {
    const parsed = qs.parse(window.location.search);
    let sumId = parsed.sum;
    if (!sumId) {
      sumId = parsed['?sum'];
    }

    return sumId ? Number(sumId) : null;
  };
  async componentDidMount() {
    const sums = await axios.get(`${base}/sums`);
    const sumId = this.getSumId();
    const sum = sums.data.find(({ id }) => id === sumId);
    this.setState({ sum });

    // mission
    try {
      const mission = await axios.get(
        `${base}/missions?postId=${this.props.match.params.id}`
      );
      if (mission.data[0]) {
        this.setState({
          missionFromRequest: mission.data[0],
          mission: mission.data[0].title,
          missionDays: mission.data[0].days,
        });
      }
    } catch (e) {
      console.error(e);
    }

    // topic
    try {
      const topic = await axios.get(
        `${base}/topics?postId=${this.props.match.params.id}`
      );
      if (topic.data[0]) {
        this.setState({
          topicFromRequest: topic.data[0],
          topic: topic.data[0].title,
        });
      }
    } catch (e) {
      console.error(e);
    }

    // qna
    try {
      const qna = await axios.get(
        `${base}/qnas?postId=${this.props.match.params.id}`
      );
      if (qna.data[0]) {
        this.setState({
          qnaFromRequest: qna.data[0],
          qna: qna.data[0].title,
        });
      }
    } catch (e) {
      console.error(e);
    }

    // poll
    try {
      const poll = await axios.get(
        `${base}/polls?postId=${this.props.match.params.id}`
      );
      if (poll.data[0]) {
        this.setState({
          pollFromRequest: poll.data[0],
          pollTitle: poll.data[0].title,
          pollOptionA: poll.data[0].optionA,
          pollOptionB: poll.data[0].optionB,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
  async componentWillMount() {
    this._isMounted = true;
    const { cacheStore, match } = this.props;
    let initFlag = true;
    let videoInputArr = [];
    let videoLen = undefined;
    let tagArr = [];
    let linkArr = [];
    let productAdded = [];
    let categorySelected = 'fashion';
    if (!this.props.userStore.auth_token) history.push('/login');
    else if (cacheStore.postEditData) {
      // 현재 수정 캐시 데이터가 있다면
      if (cacheStore.postEditData.currentEditPostData.id == match.params.id) {
        // 수정 캐시 데이터와 현재 수정하려는 포스팅이 같은 포스팅이라면
        initFlag = false; // 초기화 하지 않기
        for (var i = 0; i < this.props.postStore.editedVideoLen; i++) {
          let video, isUrl;
          if (this.props.postStore.editedVideoArr[i]) {
            video = this.props.postStore.editedVideoArr[i].video;
            isUrl = this.props.postStore.editedVideoArr[i].isUrl;
          } else {
            video = undefined;
            isUrl = false;
          }
          videoInputArr.push(
            <VideoInput
              key={i}
              index={i}
              mode="edit"
              isUrl={isUrl}
              onChange={this.handleInput}
              addVideoInput={this.addVideoInput}
              video={video}
              delVideo={this.delVideo}
            />
          );
        }
        this.setState({
          videoInputArr: videoInputArr,
        });
      } else {
        // 수정 캐시 데이터와 현재 수정하려는 포스팅이 다른 포스팅이라면
        // 수정 캐시 데이터 refresh
        this.props.postStore.clearNewPost();
      }
    }
    if (initFlag) {
      const req = {
        id: this.props.match.params.id,
        headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
      };
      const postData = await postAPI.getPost(req).then((res) => {
        return res.data;
      });
      videoLen = postData.video.length;
      for (var i = 0; i < videoLen; i++) {
        this.props.postStore.addEditedVideo(
          postData.video[i].video || postData.video[i].image,
          postData.video[i].id,
          true,
          !postData.video[i].video
        );

        let key, video, isUrl, isImage;
        key =
          `${postData.video[i].id}` ||
          postData.video[i].video ||
          postData.video[i].image ||
          i;
        isImage = this.props.postStore.editedVideoArr[i].isImage;
        video = this.props.postStore.editedVideoArr[i].video;
        isUrl = this.props.postStore.editedVideoArr[i].isUrl;

        videoInputArr.push(
          <VideoInput
            key={key}
            index={i}
            mode="edit"
            isUrl={isUrl}
            isImage={isImage}
            onChange={this.handleInput}
            addVideoInput={this.addVideoInput}
            video={video}
            delVideo={this.delVideo}
          />
        );
      }
      postData.tag.forEach(function (tag, index) {
        tagArr.push(tag.name);
      });
      postData.item.forEach(function (item, index) {
        linkArr.push({ id: index, data: item.link });
      });
      postData.item.forEach(function (item, index) {
        productAdded.push({
          id: index,
          shop: item.shopName,
          link: item.shopLink,
          shopLink: item.shopLink,
          category: item.category,
          label: item.label,
        });
        if (item.shopName) {
          categorySelected = item.category;
        }
      });

      this.props.cacheStore.setPostEditData({
        id: postData.id,
        title: postData.title,
        profile: postData.profile,
        tags: tagArr.join(', '),
        videoInputArr: videoInputArr,
        currentEditPostData: postData,
        linkArr: linkArr,
        isGetLink: true,
        linkNum: postData.item.length,
        productAdded,
        onlySelected: postData.onlySelected,
      });
      if (this._isMounted) {
        const result = removeLink(postData.title);
        this.setState({
          tagged: result.tagged,

          id: postData.id,
          title: result.str,
          profile: postData.profile,
          tags: tagArr.join(', '),
          videoInputArr: videoInputArr,
          currentEditPostData: postData,
          linkArr: linkArr,
          isGetLink: true,
          linkNum: postData.item.length,
          productAdded,
          categorySelected,
          onlySelected: postData.onlySelected,
        });
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    if (this._doUnMountFlow) {
      this.props.cacheStore.setPostEditData(this.state);
    }
  }
  handleInput = (event) => {
    const eName = event.target.name;
    if (eName == 'tags') {
      var special_pattern = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
      if (special_pattern.test(event.target.value) == true) {
        alert('태그에 특수문자는 입력할 수 없습니다.');
        this.setState({
          [eName]: this.state.tags ? this.state.tags : '',
        });
      } else {
        this.setState({ [eName]: event.target.value });
      }
    } else {
      this.setState({ [eName]: event.target.value });
    }
  };

  addVideoInput = (video) => {
    this.props.postStore.addEditedVideo(video, undefined, false);
    let videoInputArr = [];
    for (var i = 0; i < this.props.postStore.editedVideoLen; i++) {
      let video, isUrl;
      if (this.props.postStore.editedVideoArr[i]) {
        video = this.props.postStore.editedVideoArr[i].video;
        isUrl = this.props.postStore.editedVideoArr[i].isUrl;
      } else {
        video = undefined;
        isUrl = false;
      }
      videoInputArr.push(
        <VideoInput
          key={i}
          mode="edit"
          isUrl={isUrl}
          index={i}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }
    this.setState({
      videoInputArr: videoInputArr,
      videoLen: this.props.postStore.editedVideoLen,
    });
  };
  delVideo = (index) => {
    this.props.postStore.delEditedVideo(index);
    let videoInputArr = [];
    for (var i = 0; i < this.props.postStore.editedVideoLen; i++) {
      let video, isUrl;
      if (this.props.postStore.editedVideoArr[i]) {
        video = this.props.postStore.editedVideoArr[i].video;
        isUrl = this.props.postStore.editedVideoArr[i].isUrl;
      } else {
        video = undefined;
        isUrl = false;
      }
      videoInputArr.push(
        <VideoInput
          key={i}
          mode="edit"
          index={i}
          isUrl={isUrl}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }
    this.setState({
      videoInputArr: videoInputArr,
      videoLen: this.props.postStore.editedVideoLen,
    });
  };
  handleAddLink = () => {
    this.setState(
      {
        linkNum: this.state.linkNum + 1,
      },
      () => {
        scroll.scrollToBottom({
          duration: 500,
          delay: 0,
          smooth: true,
        });
      }
    );
  };
  toggleLinkDelete = () => {
    this.setState({
      deleteMode: !this.state.deleteMode,
    });
  };
  handleAddLinkArr = (id, data) => {
    const { linkArr } = this.state;
    let isNew = true;
    for (var i = 0; i < this.state.linkArr.length; i++) {
      if (this.state.linkArr[i].id == id) {
        isNew = false;
        break;
      }
    }
    if (isNew) {
      const { linkArr } = this.state;
      this.setState({
        linkArr: linkArr.concat({ id: id, data }),
      });
    } else {
      this.setState({
        linkArr: linkArr.map(
          (arr) =>
            id === arr.id
              ? { ...arr, data } // 새 객체를 만들어서 기존의 값과 전달받은 data 을 덮어씀
              : arr // 기존의 값을 그대로 유지
        ),
      });
    }
  };
  handleRemoveLinkArr = (id) => {
    const { linkArr } = this.state;
    this.setState({
      linkArr: linkArr.filter((arr) => arr.id !== id),
    });
  };
  handleTagArr = (arr) => {
    this.setState({
      tagArr: arr,
    });
  };
  formSubmit = async () => {
    const sumId = this.getSumId();
    try {
      if (!sumId) {
        alert('섬을 선택하여 주십시요.');
        return;
      }
      this.props.postStore.setLinkLoading(true);
      if (this.props.postStore.editedVideoArr.length == 0) {
        alert('영상을 하나 이상 업로드해야 합니다');
      } else if (!this.state.title) {
        alert('내용을 입력해 주세요');
      } else {
        this.setState({ loading: true });

        const tagRegExp = /#\S+/g;
        const tags = [];
        let regExpResult;
        while ((regExpResult = tagRegExp.exec(this.state.title))) {
          tags.push(regExpResult[0].slice(1));
        }
        let links = [];
        for (var i = 0; i < this.state.linkArr.length; i++) {
          links.push(this.state.linkArr[i].data);
        }
        // console.log(this.state.videoInputArr,this.props.postStore.editedVideoArr )
        const order = this.state.videoInputArr
          .map(({ props: { video } }) =>
            this.props.postStore.editedVideoArr.find((o) => o.video === video)
          )
          .map(({ id }) => id)
          .join(',');
        const req = {
          id: this.state.id,
          data: {
            title: this.state.title,
            id_of_sum: String(sumId),
            tags: tags,
            links: links.filter((o) => o !== ''),
            video: this.props.postStore.editedVideoArr,

            category: this.state.productAdded.length
              ? this.state.productAdded[0].category
              : null,
            order,
            onlySelected: sumId ? this.state.onlySelected : false,
          },
          headers: {
            Authorization: `Bearer ${this.props.userStore.auth_token}`,
          },
        };
        if (this.state.tagged.length) {
          let title = req.data.title;

          req.data.title = makeLink({ str: title, tagged: this.state.tagged });
        }

        if (this.state.productAdded.length) {
          req.data.links = this.state.productAdded.map((o) => ({
            shopName: o.shop,
            shopLink: o.link,
            link: o.link,
            category: o.category,
            label: o.label,
          }));
        }
        await postAPI.patchPost(req).then((res) => {
          return res.data;
        });
        await sendTagPush({
          ids: this.state.tagged.map(({ id }) => id),
          nickname: this.state.profile.nickname,
          postId: this.state.id,
        });

        // mission
        try {
          if (this.state.missionFromRequest) {
            if (this.state.missionFromRequest.title !== this.state.mission) {
              if (!this.state.mission || this.state.mission === '') {
                await axios.delete(
                  `${base}/missions/${this.state.missionFromRequest.id}`
                );
              } else {
                await axios.put(
                  `${base}/missions/${this.state.missionFromRequest.id}`,
                  {
                    title: this.state.mission,
                    days: this.state.missionDays,
                  }
                );
              }
            }
          } else if (this.state.mission && this.state.mission !== '') {
            await axios.post(`${base}/missions`, {
              title: this.state.mission,
              postId: this.state.id,
              days: this.state.missionDays,
            });
          }
        } catch (e) {
          console.error(e);
        }
        // topic
        try {
          if (this.state.topicFromRequest) {
            if (this.state.topicFromRequest.title !== this.state.topic) {
              if (!this.state.topic || this.state.topic === '') {
                await axios.delete(
                  `${base}/topics/${this.state.topicFromRequest.id}`
                );
              } else {
                await axios.put(
                  `${base}/topics/${this.state.topicFromRequest.id}`,
                  {
                    title: this.state.topic,
                  }
                );
              }
            }
          } else if (this.state.topic && this.state.topic !== '') {
            await axios.post(`${base}/topics`, {
              title: this.state.topic,
              postId: this.state.id,
            });
          }
        } catch (e) {
          console.error(e);
        }

        // qna
        try {
          if (this.state.qnaFromRequest) {
            if (this.state.qnaFromRequest.title !== this.state.qna) {
              if (!this.state.qna || this.state.qna === '') {
                await axios.delete(
                  `${base}/qnas/${this.state.qnaFromRequest.id}`
                );
              } else {
                await axios.put(
                  `${base}/qnas/${this.state.qnaFromRequest.id}`,
                  {
                    title: this.state.qna,
                  }
                );
              }
            }
          } else if (this.state.qna && this.state.qna !== '') {
            await axios.post(`${base}/qnas`, {
              title: this.state.qna,
              postId: this.state.id,
            });
          }
        } catch (e) {
          console.error(e);
        }

        // poll이 DB에 없는데 입력되면 새로 등록
        if (!this.state.pollFromRequest) {
          try {
            if (this.state.pollTitle && this.state.pollTitle !== '') {
              await axios.post(`${base}/polls`, {
                title: this.state.pollTitle,
                postId: this.state.id,
                optionA: this.state.pollOptionA,
                optionB: this.state.pollOptionB,
              });
            }
          } catch (e) {
            console.error(e);
          }
        }
        this.setState(
          {
            linkArr: [],
            linkNum: 1,
            tags: undefined,
            title: undefined,
          },
          () => {
            this._doUnMountFlow = false;
            this.props.cacheStore.setPostEditData(undefined);
            this.props.postStore.clearNewPost();
          }
        );
        history.push(`/sum/${sumId}?following=true`);
      }

      this.props.postStore.setLinkLoading(false);
      this.setState({ loading: false });
    } catch (e) {
      console.log(e);
      this.props.postStore.setLinkLoading(false);
      this.setState({ loading: false });
    }
  };
  _cacnel = () => {
    const f = window.confirm('리뷰 수정을 취소하시겠습니까?');
    if (f) {
      this._doUnMountFlow = false;
      this.props.cacheStore.setPostEditData(undefined);
      this.props.postStore.clearNewPost();
      window.history.back();
    }
  };
  handleFile = async (e) => {
    const isEdit = true;
    const { files } = e.target;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        let file = files[i];
        file = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        });
        file = new File([file], file.name, { type: file.type });

        this.addVideoInput(file);
        const formData = new FormData();
        formData.append('video', file);
        const req = {
          headers: this.props.userStore.auth_token
            ? {
                Authorization: `Bearer ${this.props.userStore.auth_token}`,
                'Content-Type': 'multipart/form-data',
              }
            : false,
          data: formData,
        };
        const { id } = await mediaAPI.getFramesFromMedia(req).then((res) => {
          return res.data;
        });
        const arr = isEdit
          ? this.props.postStore.editedVideoArr
          : this.props.postStore.addedVideoArr;
        const idx = arr.length - 1;
        arr[idx].id = id;
      }
    }
  };
  render() {
    const { classes } = this.props;
    const pollOn = this.state.pollTitle !== '';
    const topicOn = this.state.topic && this.state.topic !== '';
    const qnaOn = this.state.qna && this.state.qna !== '';
    const missionOn = this.state.mission && this.state.mission !== '';
    return (
      <Beforeunload onBeforeunload={() => '변경 사항이 저장되지 않습니다.'}>
        <Wrap style={{ paddingBottom: 0, backgroundColor: 'rgb(248,248,248)' }}>
          <IndexWrap style={{ paddingTop: 44, backgroundColor: '#f8f8f8' }}>
            <NavBar>
              <div style={{ width: '50%' }}>
                <img
                  src={back_ico}
                  onClick={this._cacnel}
                  style={{
                    marginLeft: 19,
                    width: 19,
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </NavBar>
            <ContentWrap>
              <WorldAndSum />
              {this.getSumId() ? (
                <FormControlLabel
                  style={{ marginLeft: 2, marginBottom: 6 }}
                  control={
                    <Switch
                      checked={this.state.onlySelected}
                      onChange={({ target: { checked } }) =>
                        this.setState({ onlySelected: checked })
                      }
                      color="primary"
                    />
                  }
                  label="선택한 섬에서만 보이기"
                />
              ) : null}
              <PostTypeSelect
                selected={
                  pollOn ? 1 : qnaOn ? 2 : missionOn ? 3 : topicOn ? 4 : 0
                }
                handleSelect={(index) => {
                  alert('포스팅 타입은 수정이 불가능합니다.');
                }}
              />

              <VideoInputWrap>
                {this.state.changing ? (
                  <ReactSortable
                    list={this.state.videoInputArr}
                    setList={(videoInputArr) => {
                      this.setState({
                        videoInputArr,
                      });
                    }}
                  >
                    {this.state.videoInputArr.map((item) => (
                      <div key={item.key} style={{ display: 'inline-block' }}>
                        {item}
                      </div>
                    ))}
                  </ReactSortable>
                ) : (
                  this.state.videoInputArr
                )}
              </VideoInputWrap>
              {this.state.videoInputArr.length > 1 && (
                <div style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() =>
                      this.setState({ changing: !this.state.changing })
                    }
                    color="primary"
                  >
                    {this.state.changing ? '변경 완료' : '순서 변경'}
                  </Button>
                </div>
              )}
              <div style={{ marginBottom: 28 }}>
                <VideoInput
                  button
                  index={this.state.videoInputArr.length}
                  mode="edit"
                  addVideoInput={this.addVideoInput}
                />
                <div
                  style={{
                    width: '50%',
                    display: 'inline-block',
                    textAlign: 'right',
                  }}
                >
                  <input
                    type="file"
                    id="file-image"
                    style={{ display: 'none' }}
                    onChange={this.handleFile}
                    accept="image/*"
                  />
                  <label htmlFor="file-image">
                    <Button
                      component="span"
                      style={{
                        boxShadow: '0 4px 16px rgba(69, 91, 99, 0.08)',
                        border: '1px solid rgba(0, 0, 0, 0)',
                        background: 'white',
                        color: '#1f75fe',
                        width: '95%',
                        borderRadius: 8,
                        padding: 12,
                        fontFamily: 'Noto Sans CJK KR',
                      }}
                    >
                      <img
                        style={{ width: 14, marginRight: 8 }}
                        src={photo_img}
                      />
                      사진 추가
                    </Button>
                  </label>
                </div>
              </div>
              <div>
                <UpdateTextArea
                  ref={(ref) => this.titleInput}
                  name="title"
                  value={this.state.title}
                  onChange={this.handleInput}
                  placeholder="내용을 입력해주세요. (해시태그는 #으로 입력)"
                  onFocus={(e) => {
                    e.preventDefault();
                    this.setState({
                      cursor: e.selectionEnd,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === '@') {
                      setTimeout(() => {
                        this.setState({ drawerOpen: true });
                      }, 300);
                    }
                    this.setState({ cursor: getCaret(e.target) });
                  }}
                />
              </div>
              <p>정보 등록</p>
              <InfoList
                items={this.state.productAdded.map((product) => ({
                  shop: product.shop,
                  link: product.link,
                  ...product,
                }))}
                onChange={(productAdded) => {
                  this.setState({
                    productAdded: productAdded.map((item) => ({
                      ...item,
                      category: 'fashion',
                      shop: item.shop,
                      name: '클릭시 상품 링크로 이동합니다.',
                      label: null,
                    })),
                  });
                }}
              />

              <SubmitWrap>
                <SubmitBtn
                  onClick={this.formSubmit}
                  disabled={this.props.postStore.linkLoading}
                >
                  {' '}
                  저장하기{' '}
                </SubmitBtn>
              </SubmitWrap>
            </ContentWrap>
          </IndexWrap>
        </Wrap>
        {this.state.productAddPageOn && (
          <ProductAddPageLayout>
            <ProductAddPage
              onBack={() => this.setState({ productAddPageOn: false })}
              onSubmit={(submitted) => {
                if (this.state.selectedProduct) {
                  const productAddedSliced = this.state.productAdded.slice();
                  const idx = productAddedSliced.findIndex(
                    (o) => o.id === this.state.selectedProduct.id
                  );
                  productAddedSliced[idx] = {
                    ...submitted,
                    id: this.state.selectedProduct.id,
                    category: this.state.selectedProduct.category,
                  };
                  this.setState({
                    productAdded: productAddedSliced,
                    productAddPageOn: false,
                  });
                } else {
                  this.setState({
                    productAdded: this.state.productAdded.concat({
                      ...submitted,
                      id: `${Math.random()}`,
                      category: this.state.categorySelected,
                    }),
                    productAddPageOn: false,
                  });
                }
              }}
              categoryItems={
                this.state.sum
                  ? this.state.sum.categories.map((o) => o.name)
                  : []
              }
              selectedProduct={this.state.selectedProduct}
            />
          </ProductAddPageLayout>
        )}
        {this.state.loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClipLoader
              sizeUnit={'px'}
              size={36}
              color={'rgb(130, 143, 247)'}
              loading={true}
            />
          </div>
        )}
        <DeleteModal
          active={this.state.deleteIndex > -1}
          onClick={() => this.handleDeleteProduct(this.state.deleteIndex)}
          handleClose={() => this.setState({ deleteIndex: -1 })}
        />

        <TopicModal
          open={this.state.topicOpen}
          onClose={() => this.setState({ topicOpen: false })}
          value={this.state.topic}
          onChange={(e) => this.setState({ topic: e.target.value })}
          onSubmit={(value) =>
            this.setState({
              topic: value === '' ? null : value,
              topicOpen: false,
            })
          }
          onRemove={() => this.setState({ topic: null, topicOpen: false })}
        />
        <QnaModal
          open={this.state.qnaOpen}
          onClose={() => this.setState({ qnaOpen: false })}
          value={this.state.qna}
          onChange={(e) => this.setState({ qna: e.target.value })}
          onSubmit={(value) =>
            this.setState({
              qna: value === '' ? null : value,
              qnaOpen: false,
            })
          }
          onRemove={() => this.setState({ qna: null, qnaOpen: false })}
        />
        <PollModal
          input
          open={this.state.pollOpen}
          onClose={() => this.setState({ pollOpen: false })}
          title={this.state.pollTitle}
          onTitleChange={(e) => this.setState({ pollTitle: e.target.value })}
          pollOptionA={this.state.pollOptionA}
          onPollOptionAChange={(e) =>
            this.setState({ pollOptionA: e.target.value })
          }
          pollOptionB={this.state.pollOptionB}
          onPollOptionBChange={(e) =>
            this.setState({ pollOptionB: e.target.value })
          }
          onSubmit={() => {
            if (this.state.pollTitle === '') {
              window.alert('타이틀을 입력해주십시요.');
            } else if (
              this.state.pollOptionA === '' ||
              this.state.pollOptionB === ''
            ) {
              window.alert('투표안을 입력해주십시요.');
            } else {
              this.setState({ pollOpen: false });
            }
          }}
          onRemove={() =>
            this.setState({
              pollTitle: '',
              pollOptionA: '',
              pollOptionB: '',
              pollOpen: false,
            })
          }
        />
        <UserSelectorModal
          open={this.state.drawerOpen}
          onClose={() => this.setState({ drawerOpen: false })}
          onSelect={(data) => {
            const { cursor } = this.state;
            const nextTitle = [
              this.state.title.slice(0, cursor),
              `@${data.nickname} `,
              this.state.title.slice(cursor + 1),
            ].join('');

            this.setState({
              drawerOpen: false,
              title: nextTitle,

              tagged: this.state.tagged.find((p) => p.id === data.id)
                ? this.state.tagged
                : this.state.tagged.concat(data),
            });
          }}
        />
      </Beforeunload>
    );
  }
}

export default withStyles(styles)(PostEditPage);
