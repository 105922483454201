import React from 'react';
import styled, { css } from 'styled-components';

const Wrap = styled.div`
	position: absolute;
	text-align: center;
	z-index: 10;
	display: none;
	right: 0px;
	top: 31px;
	width: 100%;
	line-height: 26px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background-color: #ffffff;
	${props =>
		props.toggle &&
		css`
			display: block;
		`}
`;
const FilterDownText = styled.div`
	color: #828ff7;
	font-family: 'Noto Sans CJK KR';
	font-size: 13px;
	font-weight: 700;
	padding: 4px 0px;
	cursor: pointer;
	:hover,
	focus {
		opacity: 0.8;
	}
`;

class FilterDown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {}
	render() {
		return (
			<Wrap toggle={this.props.toggle}>
				<FilterDownText onClick={() => this.props.handleFilter('popular')}>
					인기순
				</FilterDownText>
				<FilterDownText onClick={() => this.props.handleFilter('new')}>
					최신순
				</FilterDownText>
			</Wrap>
		);
	}
}

export default FilterDown;
